import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    lightweight: true;
    lightOutlined: true;
    neutral: true;
    dark: true;
  }
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    neutral: PaletteColorOptions;
    dark: PaletteColorOptions;
  }
}

export const defaultTheme = createTheme({
  typography: {
    subtitle1: {
      fontSize: '1.2rem',
    },
    subtitle2: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '600',
      paddingTop: '10px',
    },
  },
  palette: {
    neutral: {
      main: '#444',
      contrastText: '#fff',
    },
    dark: {
      main: '#333',
      contrastText: '#fff',
    },
    error: {
      main: '#D21924',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: 'lightweight' },
          style: {
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: 'normal',
          },
        },
        {
          props: { variant: 'lightOutlined' },
          style: {
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: 'normal',
            border: '1px solid black',
          },
        },
        {
          props: { variant: 'dark' },
          style: {
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: 'normal',
            backgroundColor: '#333',
            color: 'white',
            '&:hover': {
              background: '#666',
            },
            '&.Mui-disabled': {
              background: '#bbb',
              color: '#555',
            },
          },
        },
      ],
    },
  },
});
