import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { documentTypeSystemNameMapping } from '../helpers/documentTypeMappings';
import { RequiredDocument } from './Underwriting/RequiredDocument';

const DocumentList = ({ docs, handleUpdate }) => {
  const documentGroups = [
    ...new Set(Object.values(documentTypeSystemNameMapping)),
  ];

  const groupedDocs = {};

  // Group documents based on the mapping
  docs.forEach((doc) => {
    const systemName = doc.document_type.system_name;
    const mappingGroup = documentTypeSystemNameMapping[systemName] || 'Other';
    if (!groupedDocs[mappingGroup]) {
      groupedDocs[mappingGroup] = [];
    }
    groupedDocs[mappingGroup].push(doc);
  });

  return (
    <>
      {documentGroups.map((docGroup) => {
        const groupDocs = groupedDocs[docGroup] || [];
        const accordionLabel = `${docGroup} (${groupDocs.length} document${
          groupDocs.length === 1 ? '' : 's'
        })`;

        return (
          <Accordion
            key={docGroup}
            defaultExpanded
            sx={{
              borderRadius: 2,
              '&.Mui-expanded': {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              aria-controls={`${docGroup}-content`}
              id={`${docGroup}-header`}
              expandIcon={<ExpandMoreIcon />}
              className="flex-gap-0_5 flex-row-start-reverse"
            >
              <Typography variant="h6">{accordionLabel}</Typography>
            </AccordionSummary>
            {groupDocs.length > 0 ? (
              <AccordionDetails>
                <Grid container spacing={1.75}>
                  {groupDocs.map((doc) => (
                    <Grid item xs={12} md={6} xl={4} key={doc.id}>
                      <RequiredDocument
                        key={doc.approval_status}
                        document={doc}
                        onUpdate={handleUpdate}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            ) : (
              <AccordionDetails>
                <Typography variant="body2">
                  No documents of this type.
                </Typography>
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}

      {/* Other section */}
      {groupedDocs['OTHER'] && groupedDocs['OTHER'].length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls="other-content"
            id="other-header"
            expandIcon={<ExpandMoreIcon />}
            className="flex-gap-0_5 flex-row-start-reverse"
          >
            <Typography variant="h6">
              Other ({groupedDocs['OTHER'].length} documents)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1.75}>
              {groupedDocs['OTHER'].map((doc) => (
                <Grid item xs={12} md={6} xl={4} key={doc.id}>
                  <RequiredDocument
                    key={doc.approval_status}
                    document={doc}
                    onUpdate={handleUpdate}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default DocumentList;
