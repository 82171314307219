import React, { useEffect, useState } from 'react';
import { fetchDocumentTypes } from '../pages/loanOps/FileManagement';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Error } from '@mui/icons-material';
import { Loading } from './Loading';

const RequiredDocumentModal = ({
  open,
  onClose,
  onSubmit,
  initialDocument = null,
  editMode = false,
}) => {
  const [requiredDocument, setRequiredDocument] = useState<Record<string, any>>(
    initialDocument ? initialDocument : {},
  );
  const [documentTypes, setDocumentTypes] = useState<Record<string, any>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    setRequiredDocument((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (editMode) {
      const docType = documentTypes.filter(
        (type) => type.name == requiredDocument['document_type'],
      );
      const {
        document_type_id,
        approved_by_user_username,
        document_type,
        ...rest
      } = requiredDocument;
      let payload = {
        ...rest,
        document_type_id: docType[0].id,
      };

      onSubmit(payload)
        .then(() => {
          setIsLoading(false);
          onClose();
        })
        .catch((err) => {
          setError(err.message);
          setIsLoading(false);
        });
    } else {
      onSubmit(requiredDocument)
        .then(() => {
          setIsLoading(false);
          setRequiredDocument({});
          onClose();
        })
        .catch((err) => {
          setError(err.message);
          setRequiredDocument({});
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchDocumentTypes().then((docTypes) => {
      setDocumentTypes(docTypes);
      if (initialDocument) {
        const docType = docTypes.filter(
          (type) => type.id == initialDocument['document_type_id'],
        );
        setRequiredDocument((prev) => {
          // add document_type
          return {
            ...prev,
            document_type: docType[0].name,
          };
        });
      }
    });
  }, [initialDocument]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {editMode ? 'Edit Requirement' : 'Create Requirement'}
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {editMode && !requiredDocument ? (
          <DialogContent>
            <Loading />{' '}
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid container spacing={1.5}>
              {error ? (
                <Grid item md={12}>
                  <Box className="flex-row-end flex-gap-0_5">
                    <Error color="error" />
                    <Typography variant="subtitle1">{error}</Typography>
                  </Box>
                </Grid>
              ) : null}

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Document Type"
                  name="document_type"
                  onChange={handleChange}
                  select
                  required
                  value={requiredDocument.document_type || ''}
                  variant="outlined"
                >
                  {documentTypes.map((docType) => (
                    <MenuItem value={docType.name} key={docType.name}>
                      {docType.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Requirement Name"
                  name="requirement_name"
                  onChange={handleChange}
                  required
                  value={requiredDocument.requirement_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Requirement Description"
                  name="requirement_description"
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={3}
                  defaultValue={
                    requiredDocument?.requirement_description?.length > 0
                      ? requiredDocument.requirement_description
                      : null
                  }
                  required={requiredDocument.document_type === 'Other'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Status"
                  name="approval_status"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.approval_status || ''}
                  variant="outlined"
                >
                  <MenuItem value="VERIFIED">VERIFIED</MenuItem>
                  <MenuItem value="NEEDS_REVIEW">NEEDS REVIEW</MenuItem>
                  <MenuItem value="ISSUES_IDENTIFIED">
                    ISSUES IDENTIFIED
                  </MenuItem>
                  <MenuItem value="MISSING">MISSING</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Source"
                  name="document_approval_source"
                  onChange={handleChange}
                  required
                  select
                  value={requiredDocument?.document_approval_source || ''}
                  variant="outlined"
                >
                  <MenuItem value="DOCUSIGN">DOCUSIGN</MenuItem>
                  <MenuItem value="UPLOAD">WEB APP UPLOAD</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={onClose}
            variant="outlined"
            color="inherit"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {editMode ? 'Edit Requirement' : 'Create Requirement'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RequiredDocumentModal;
