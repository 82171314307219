import { Drawer, Slide } from '@mui/material';
import React from 'react';

export const BetterDrawer = (props) => {
  return (
    <Drawer
      {...props}
      TransitionComponent={(props) => <Slide {...props} timeout={0} />}
    >
      {props.children}
    </Drawer>
  );
};
