import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
  accordionSummaryClasses,
} from '@mui/material';
import React, { useState } from 'react';
import { AddCheckModal } from './AddCheckModal';
import { DocumentCheck } from './DocumentCheck';
import { StatusIcon } from './StatusIcon';
import {
  ChecklistItemRule,
  ChecklistItemVerificationStatus,
} from './constants';

export const ChecklistDocument = ({
  document,
  status,
  documentChecks,
  addCheck,
  deleteCheck,
  updateCheck,
  parentChecksByTitle,
  checklistId,
  readOnly = false,
}) => {
  const documentName = document.Display_Name__c;
  const approvalStatus = document.Approval_Status__c;

  const [addCheckModalOpen, setAddCheckModalOpen] = useState(false);

  return (
    <div key={document.Id}>
      <Accordion
        defaultExpanded={status !== ChecklistItemVerificationStatus.VERIFIED}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore htmlColor="#333333" />}
          sx={{
            px: 0,
            flexDirection: 'row-reverse',
            [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
              transform: 'rotate(-90deg)',
              [`&.${accordionSummaryClasses.expanded}`]: {
                transform: 'rotate(0deg)',
              },
            },
          }}
        >
          <Box display="flex" alignItems={'center'}>
            <Typography
              variant="h6"
              mr={0.5}
              fontSize={18}
              fontWeight={'bold'}
              fontFamily={'Lato'}
            >
              {documentName}
            </Typography>
            <StatusIcon status={status} statusType="verification" />
            <StatusIcon
              status={approvalStatus}
              statusType="document_approval"
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
          }}
        >
          {documentChecks.length ? (
            <Grid container spacing={1.5} key={document.Id}>
              {documentChecks.map((check, i) => {
                const isChildCheck = check.rule === ChecklistItemRule.EQUAL;
                const parentCheck = isChildCheck
                  ? parentChecksByTitle[check.title]
                  : null;
                return (
                  <Grid item xs={12} key={check.id}>
                    <DocumentCheck
                      check={check}
                      updateCheck={updateCheck}
                      deleteCheck={deleteCheck}
                      parentCheckValue={parentCheck?.inputValue}
                      readOnly={readOnly}
                      showColumnTitles={i === 0}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : null}
          {!readOnly && (
            <Button
              variant="text"
              color="inherit"
              sx={{
                textDecoration: 'underline',
                fontSize: 14,
                mt: 1,
              }}
              onClick={() => {
                setAddCheckModalOpen(true);
              }}
            >
              + Add New Verification
            </Button>
          )}
        </AccordionDetails>
      </Accordion>

      <AddCheckModal
        open={addCheckModalOpen}
        onClose={() => {
          setAddCheckModalOpen(false);
        }}
        onSubmit={(settings) => {
          return addCheck({
            sunstoneDocumentName: documentName,
            inputValue: '',
            orderNumber: documentChecks.length + 1,
            checklistId: checklistId,
            ...settings,
          });
        }}
      />
    </div>
  );
};
