import { SFLoanApplication } from '@backend/types/Loan';
import { Error } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import DocumentIssuesModal from '../../components/DocumentIssuesModal';
import DocumentList from '../../components/FileManagementDocumentList';
import RequiredDocumentModal from '../../components/RequiredDocumentModal';

export const fetchDocumentTypes = async () => {
  return await axios
    .get('/api/v1/loans/document-types/')
    .then((r) => r.data)
    .catch(console.error);
};

const getApplicationRequiredDocuments = async (appId) => {
  return await axios
    .get('/api/v1/loans/required-documents/' + appId)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
    });
};

const createRequiredDocument = async (document) => {
  return await axios
    .post(`/api/v1/loans/required-documents/`, document)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
    });
};

export const FileManagement = () => {
  const app = useOutletContext() as SFLoanApplication;
  const appId = app.Id;
  const appName = app.Name;

  const [requiredDocuments, setRequiredDocuments] = useState<
    Record<string, any>[]
  >([]);
  const [newRequirementModalOpen, setNewRequirementModalOpen] = useState(false);
  const [documentIssuesModalOpen, setdocumentIssuesModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const toggleNewRequirementModal = () =>
    setNewRequirementModalOpen((prev) => !prev);

  const toggleDocumentIssuesModal = () =>
    setdocumentIssuesModalOpen((prev) => !prev);

  const handleDocumentUpdate = async () => {
    const updatedDocs = await getApplicationRequiredDocuments(appId);
    setRequiredDocuments(updatedDocs);
  };

  const handleCreateRequiredDocument = async (document) => {
    document.parent_id = appId;
    const res = await createRequiredDocument(document);
    handleDocumentUpdate();
  };

  useEffect(() => {
    getApplicationRequiredDocuments(appId).then((res) => {
      setRequiredDocuments(res);
    });
  }, [appId]);

  const handleDownloadAll = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await fetch(
        `/api/v1/loans/required-documents/${appId}/zip`,
      );
      /* the below logic creates a download link and clicks it 
      to download the file returned from the fetch request */
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = `${appName}_Railway_LFM_RequiredDocuments.zip`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error: { message: string } | any) {
      console.error('Error downloading file:', error);
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <Box className="content">
      <Box
        display="flex"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h5">{`Uploaded Documents (${requiredDocuments.length} documents)`}</Typography>
        <Box>
          <Button
            variant="outlined"
            onClick={toggleNewRequirementModal}
            color="inherit"
            sx={{ mx: 0.5 }}
          >
            Create New Requirement
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ mx: 0.5 }}
            disabled={loading}
            onClick={handleDownloadAll}
          >
            {loading ? (
              <Box
                display="flex"
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <CircularProgress
                  size=".875rem"
                  sx={{ mr: '.5rem' }}
                  color="inherit"
                />
                <Typography>Downloading ...</Typography>
              </Box>
            ) : (
              'Download All'
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={toggleDocumentIssuesModal}
            color="inherit"
            sx={{ mx: 0.5 }}
          >
            View List of Issues
          </Button>
        </Box>
      </Box>
      {error.length > 0 ? (
        <Box className="flex-row-end flex-gap-0_5">
          <Error color="error" />
          <Typography color="error">
            Something went wrong downloading your files
          </Typography>
        </Box>
      ) : null}

      <DocumentList
        docs={requiredDocuments}
        handleUpdate={handleDocumentUpdate}
      />
      <RequiredDocumentModal
        open={newRequirementModalOpen}
        onClose={toggleNewRequirementModal}
        onSubmit={handleCreateRequiredDocument}
      />
      <DocumentIssuesModal
        open={documentIssuesModalOpen}
        onClose={toggleDocumentIssuesModal}
        requiredDocuments={requiredDocuments}
      />
    </Box>
  );
};
