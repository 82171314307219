import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import {
  ChecklistItemRule,
  ChecklistItemType,
  ChecklistItemVerificationStatus,
} from './constants';
import React from 'react';

const initialValues = {
  title: '',
  rule: '',
  type: '',
  isRequired: false,
};
export const AddCheckModal = ({ open, onClose, onSubmit }) => {
  const [settings, setSettings] = useState(initialValues);

  const [error, setError] = useState({ message: '' });
  const handleChange = useCallback((e) => {
    setSettings((settings) => ({
      ...settings,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleCheckboxChange = useCallback((e) => {
    setSettings((settings) => ({
      ...settings,
      [e.target.name]: e.target.checked,
    }));
  }, []);

  const handleClose = () => {
    onClose();
    setError({ message: '' });
    setSettings(initialValues);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ p: 2 }}
    >
      <DialogTitle>
        Add New Custom Field
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const result = await onSubmit({
            ...settings,
            verificationStatus:
              settings.type === ChecklistItemType.DOCUMENT_REQUIREMENT
                ? ChecklistItemVerificationStatus.NOT_VERIFIED
                : settings.type === ChecklistItemType.CALL
                ? ChecklistItemVerificationStatus.NOT_CALLED
                : settings.type === ChecklistItemType.DOCUSIGN
                ? ChecklistItemVerificationStatus.NOT_SENT
                : ChecklistItemVerificationStatus.NOT_VERIFIED,
          });
          if (result.error) {
            setError(result.error);
          } else {
            handleClose();
          }
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                size="small"
                fullWidth
                value={settings.title}
                name="title"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Check Rule"
                variant="outlined"
                size="small"
                select
                fullWidth
                value={settings.rule}
                name="rule"
                onChange={handleChange}
              >
                <MenuItem value={ChecklistItemRule.EXIST}>
                  Exist (Check if the requirement is present and fill the
                  present value)
                </MenuItem>
                <MenuItem value={ChecklistItemRule.EQUAL}>
                  Equal (Check if the requirement is equal to the value in a
                  previous document)
                </MenuItem>
                <MenuItem value={ChecklistItemRule.YES_NO}>
                  Yes/No (Check if the requirement is satisfied)
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Check Type"
                variant="outlined"
                size="small"
                select
                fullWidth
                value={settings.type}
                name="type"
                onChange={handleChange}
              >
                <MenuItem value={ChecklistItemType.DOCUMENT_REQUIREMENT}>
                  Document Requirement
                </MenuItem>
                <MenuItem value={ChecklistItemType.CALL}>Call</MenuItem>
                <MenuItem value={ChecklistItemType.DOCUSIGN}>Docusign</MenuItem>
                {/* <MenuItem value="EMAIL">Email</MenuItem> */}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                label={'Required'}
                control={
                  <Checkbox
                    checked={settings.isRequired}
                    name="isRequired"
                    onChange={handleCheckboxChange}
                  />
                }
              />
            </Grid>
          </Grid>

          {error && (
            <Typography color="error">
              {error?.message ?? 'Something went wrong'}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
