export function isValidURL(url: string): boolean {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );

  return !!urlPattern.test(url);
}

// Calculates the difference between date2 and date1
// Coverts that difference into a string with the smallest unit of time
// e.g. 10m, 2h, 3d, >30d
export const smallDateDiff = (date1: Date, date2: Date = new Date()) => {
  let result = '';
  const diffTime = (date2.getTime() - date1.getTime()) / 60000;

  // negative diffTime
  if (diffTime < 0) {
    result = '--';
    // 60 minutes
  } else if (diffTime < 60) {
    result = `${Math.floor(diffTime)}m`;
    // 24 hours
  } else if (diffTime < 1440) {
    result = `${Math.floor(diffTime / 60)}h`;
    // 120 days
  } else if (diffTime <= 172800) {
    result = `${Math.floor(diffTime / 1440)}d`;
  } else {
    result = `>120d`;
  }

  return result;
};
