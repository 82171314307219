export const ChecklistItemVerificationStatus = {
  // Document requirement status
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFIED: 'VERIFIED',
  ISSUES_IDENTIFIED: 'ISSUES_IDENTIFIED',
  MISSING: 'MISSING',
  NOT_NEEDED: 'NOT_NEEDED',
  // Docusign status
  SENT: 'SENT',
  SIGNED: 'SIGNED',
  NOT_SENT: 'NOT_SENT',
  // Call status
  CALLED_SPOKE_TO: 'CALLED_SPOKE_TO',
  CALLED_VOICEMAIL: 'CALLED_VOICEMAIL',
  REQUIRED: 'REQUIRED',
  NOT_CALLED: 'NOT_CALLED',
};

// note: duplicated in /backend/src/constants.ts
export const ChecklistItemType = {
  DOCUMENT_REQUIREMENT: 'DOCUMENT_REQUIREMENT',
  CALL: 'CALL',
  DOCUSIGN: 'DOCUSIGN',
};

// note: duplicated in /backend/src/constants.ts
export const ChecklistItemRule = {
  EXIST: 'EXIST',
  EQUAL: 'EQUAL',
  YES_NO: 'YES_NO',
};
