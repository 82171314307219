export const DocumentGroups = {
  BANK_STATEMENTS: 'Bank Statements',
  BUSINESS_DOCUMENTS: 'Business Documents',
  OTHER: 'Other',
  PRINCIPAL_DOCUMENTS: 'Principal Documents',
  PROPERTY_DOCUMENTS: 'Property Documents',
  SOLAR_ECONOMICS: 'Solar Economics',
  TAX_FINANCIAL_DOCUMENTS: 'Tax/Financial Documents',
};

export const documentTypeSystemNameMapping = {
  /* bank statements */
  'bank-statements-3-months-ago': DocumentGroups.BANK_STATEMENTS,
  'bank-statement-1-month-ago': DocumentGroups.BANK_STATEMENTS,
  'bank-statements-last-month': DocumentGroups.BANK_STATEMENTS,
  'bank-statements-2-month-ago': DocumentGroups.BANK_STATEMENTS,
  /* business documents */
  'non-profit-board-resolution': DocumentGroups.BUSINESS_DOCUMENTS,
  'member-assessment-policy': DocumentGroups.BUSINESS_DOCUMENTS,
  'non-profit-bylaws': DocumentGroups.BUSINESS_DOCUMENTS,
  'certificate-of-trust-or-executed-trust-agreement':
    DocumentGroups.BUSINESS_DOCUMENTS,
  'change-order-contract': DocumentGroups.BUSINESS_DOCUMENTS,
  'religious-organization-attendance': DocumentGroups.BUSINESS_DOCUMENTS,
  'articles-of-organization-incorporation': DocumentGroups.BUSINESS_DOCUMENTS,
  'religious-organization-donations': DocumentGroups.BUSINESS_DOCUMENTS,
  'irs-form-ss-4': DocumentGroups.BUSINESS_DOCUMENTS,
  'meeting-minutes': DocumentGroups.BUSINESS_DOCUMENTS,
  /* other */
  'additional-document': DocumentGroups.OTHER,
  other: DocumentGroups.OTHER,
  /* principal documents */
  'principal-id': DocumentGroups.PRINCIPAL_DOCUMENTS,
  /* property documents */
  'utility-bills': DocumentGroups.PROPERTY_DOCUMENTS,
  'offtaker-lease-or-proof-of-ownership': DocumentGroups.PROPERTY_DOCUMENTS,
  'proof-of-ownership-of-the-system-property':
    DocumentGroups.PROPERTY_DOCUMENTS,
  'proof-of-offtaker-creditworthiness': DocumentGroups.PROPERTY_DOCUMENTS,
  'lease-of-the-system-property': DocumentGroups.PROPERTY_DOCUMENTS,
  'offtaker-utility-bill': DocumentGroups.PROPERTY_DOCUMENTS,
  'current-occupant-ledger': DocumentGroups.PROPERTY_DOCUMENTS,
  'offtaker-ppa-or-lease-agreement': DocumentGroups.PROPERTY_DOCUMENTS,
  'property-company-rent-roll': DocumentGroups.PROPERTY_DOCUMENTS,
  'property-company-mortgage-statement': DocumentGroups.PROPERTY_DOCUMENTS,
  /* solar economics */
  'solar-loan-proposal': DocumentGroups.SOLAR_ECONOMICS,
  'incentive-documents': DocumentGroups.SOLAR_ECONOMICS,
  'ppa-lease-financial-projections': DocumentGroups.SOLAR_ECONOMICS,
  /* tax  and financial documents */
  'tax-returns-last-year': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'operating-and-reserve-budgets': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'tax-returns-2-years-ago': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'financial-statements-current-year': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'tax-returns-extension-form-7004': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'tax-returns-3-years-ago': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'financial-statements-last-year': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'pg-tax-returns-1-year-ago': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'pg-tax-returns-2-years-ago': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'pg-tax-extension-1-year-ago': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
  'pg-tax-extension-2-years-ago': DocumentGroups.TAX_FINANCIAL_DOCUMENTS,
};
