import { SFLoanApplication } from '@backend/types/Loan';
import { Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import skynetLogo from '../../../assets/img/ic-skynet-small.png';
import { OracleInsight } from '../../../components/Underwriting/OracleInsight';
import { isAdmin } from '../../../helpers/auth';
import { OracleDocUploadDialog } from '../../oracle/OracleDocUploadDialog';

export const OracleInsights = () => {
  const app = useOutletContext() as SFLoanApplication;
  const appId = app.Id;

  const [oracleDocAnalysisModalOpen, setOracleDocAnalysisModalOpen] =
    useState(false);

  const [hasDataFromOracle, setHasDataFromOracle] = useState(false);
  const [oracleInsightsData, setOracleInsightsData] = useState([]);

  const handleOracleResultsComplete = () => {
    getOracleInsights();
    toggleOracleDocAnalysisModal();
  };

  const toggleOracleDocAnalysisModal = () =>
    setOracleDocAnalysisModalOpen((prev) => !prev);

  const handleGenerateBorrowerAnalysis = async () => {
    try {
      const oracleResponse = await axios.post(
        `/api/v1/oracle/loan-analysis?appId=${appId}`,
      );

      // Refresh the insights by pulling the latest
      getOracleInsights();
    } catch (error) {
      console.error('There was an error fetching Oracle data', error);
    }
  };
  const getOracleInsights = async () => {
    try {
      const oracleResponse = await axios.get(
        `/api/v1/oracle/insights?appId=${appId}`,
      );
      setHasDataFromOracle(Object.keys(oracleResponse.data).length > 0);
      setOracleInsightsData(oracleResponse.data);
    } catch (error) {
      console.error('There was an error fetching Oracle data', error);
    }
  };

  useEffect(() => {
    getOracleInsights();
  }, [appId]);

  return (
    <Box className="content">
      {isAdmin() ? (
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box justifyContent={'space-between'} alignItems={'right'}>
            <Button
              variant="outlined"
              onClick={toggleOracleDocAnalysisModal}
              color="inherit"
              sx={{ mx: 0.5 }}
            >
              <img
                src={skynetLogo}
                style={{ width: '25px', marginRight: '5px' }}
              />
              Create New Oracle Analysis
            </Button>
            <Button
              variant="outlined"
              onClick={handleGenerateBorrowerAnalysis}
              color="inherit"
              sx={{ mx: 0.5 }}
            >
              <img
                src={skynetLogo}
                style={{ width: '25px', marginRight: '5px' }}
              />
              Generate Borrower Analysis
            </Button>
          </Box>
        </Box>
      ) : null}

      {hasDataFromOracle ? (
        oracleInsightsData.map((insight) => (
          <Grid item xs={12} md={6} xl={4} key={insight.id}>
            <OracleInsight oracleInsight={insight} />
          </Grid>
        ))
      ) : (
        <Typography variant="body2">
          No insights processed for this application.
        </Typography>
      )}

      {oracleDocAnalysisModalOpen && (
        <OracleDocUploadDialog
          applicationId={appId}
          open
          onClose={toggleOracleDocAnalysisModal}
          onSuccess={handleOracleResultsComplete}
        />
      )}
    </Box>
  );
};
