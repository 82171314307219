export const CO_UTILITES = ['Xcel Energy', 'Other'];

export const IL_UTILITES = [
  'Ameren Illinois',
  'Commonwealth Edison',
  'MidAmerican Energy',
  'Mt. Carmel',
  'Other (MISO)',
  'Other (PJM)',
];

export const MA_TERRITORIES = [
  'Eversource East',
  'Eversource West',
  'National Grid (Massachusetts Electric)',
  'National Grid (Nantucket)',
  'Unitil',
  'Other',
];

// TODO: refactor into Railway call?
export function getRecInfoChoicesByState(state: string) {
  let choices: string[] = [];
  switch (state) {
    case 'Colorado':
      choices = CO_UTILITES;
      break;
    case 'Illinois':
      choices = IL_UTILITES;
      break;
    case 'Massachusetts':
      choices = MA_TERRITORIES;
      break;
  }
  return choices;
}

export const UNITED_STATES_FOR_DROPDOWN = [
  { label: 'Alabama', value: 'Alabama', abbreviation: 'AL' },
  { label: 'Alaska', value: 'Alaska', abbreviation: 'AK' },
  { label: 'Arizona', value: 'Arizona', abbreviation: 'AZ' },
  { label: 'Arkansas', value: 'Arkansas', abbreviation: 'AR' },
  { label: 'California', value: 'California', abbreviation: 'CA' },
  { label: 'Colorado', value: 'Colorado', abbreviation: 'CO' },
  { label: 'Connecticut', value: 'Connecticut', abbreviation: 'CT' },
  { label: 'Delaware', value: 'Delaware', abbreviation: 'DE' },
  {
    label: 'District of Columbia (Washington, D.C.)',
    value: 'Washington, D.C.',
    abbreviation: 'DC',
  },
  { label: 'Florida', value: 'Florida', abbreviation: 'FL' },
  { label: 'Georgia', value: 'Georgia', abbreviation: 'GA' },
  { label: 'Hawaii', value: 'Hawaii', abbreviation: 'HI' },
  { label: 'Idaho', value: 'Idaho', abbreviation: 'ID' },
  { label: 'Illinois', value: 'Illinois', abbreviation: 'IL' },
  { label: 'Indiana', value: 'Indiana', abbreviation: 'IN' },
  { label: 'Iowa', value: 'Iowa', abbreviation: 'IA' },
  { label: 'Kansas', value: 'Kansas', abbreviation: 'KS' },
  { label: 'Kentucky', value: 'Kentucky', abbreviation: 'KY' },
  { label: 'Louisiana', value: 'Louisiana', abbreviation: 'LA' },
  { label: 'Maine', value: 'Maine', abbreviation: 'ME' },
  { label: 'Maryland', value: 'Maryland', abbreviation: 'MD' },
  { label: 'Massachusetts', value: 'Massachusetts', abbreviation: 'MA' },
  { label: 'Michigan', value: 'Michigan', abbreviation: 'MI' },
  { label: 'Minnesota', value: 'Minnesota', abbreviation: 'MN' },
  { label: 'Mississippi', value: 'Mississippi', abbreviation: 'MS' },
  { label: 'Missouri', value: 'Missouri', abbreviation: 'MO' },
  { label: 'Montana', value: 'Montana', abbreviation: 'MT' },
  { label: 'Nebraska', value: 'Nebraska', abbreviation: 'NE' },
  { label: 'Nevada', value: 'Nevada', abbreviation: 'NV' },
  { label: 'New Hampshire', value: 'New Hampshire', abbreviation: 'NH' },
  { label: 'New Jersey', value: 'New Jersey', abbreviation: 'NJ' },
  { label: 'New Mexico', value: 'New Mexico', abbreviation: 'NM' },
  { label: 'New York', value: 'New York', abbreviation: 'NY' },
  { label: 'North Carolina', value: 'North Carolina', abbreviation: 'NC' },
  { label: 'North Dakota', value: 'North Dakota', abbreviation: 'ND' },
  { label: 'Ohio', value: 'Ohio', abbreviation: 'OH' },
  { label: 'Oklahoma', value: 'Oklahoma', abbreviation: 'OK' },
  { label: 'Oregon', value: 'Oregon', abbreviation: 'OR' },
  { label: 'Pennsylvania', value: 'Pennsylvania', abbreviation: 'PA' },
  { label: 'Rhode Island', value: 'Rhode Island', abbreviation: 'RI' },
  { label: 'South Carolina', value: 'South Carolina', abbreviation: 'SC' },
  { label: 'South Dakota', value: 'South Dakota', abbreviation: 'SD' },
  { label: 'Tennessee', value: 'Tennessee', abbreviation: 'TN' },
  { label: 'Texas', value: 'Texas', abbreviation: 'TX' },
  { label: 'Utah', value: 'Utah', abbreviation: 'UT' },
  { label: 'Vermont', value: 'Vermont', abbreviation: 'VT' },
  { label: 'Virginia', value: 'Virginia', abbreviation: 'VA' },
  { label: 'Washington', value: 'Washington', abbreviation: 'WA' },
  { label: 'West Virginia', value: 'West Virginia', abbreviation: 'WV' },
  { label: 'Wisconsin', value: 'Wisconsin', abbreviation: 'WI' },
  { label: 'Wyoming', value: 'Wyoming', abbreviation: 'WY' },
];

export const UserGroups = {
  INSTALLER: 'INSTALLER',
  CONSULTANT: 'CONSULTANT',
  INSTALLER_AND_CONSULTANT: 'INSTALLER_AND_CONSULTANT',
  BORROWER: 'BORROWER',
  ALL: 'ALL',
};

// announcements
export const LIVE_ANNOUNCEMENTS_LIMIT = 4;
export const ANNOUNCEMENT_MAX_BODY_LENGTH = 200;
