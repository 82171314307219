import {
  Check,
  Close,
  ExpandCircleDownOutlined,
  Visibility,
} from '@mui/icons-material';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Typography,
  accordionSummaryClasses,
} from '@mui/material';
import axios from 'axios';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { BetterAccordion } from '../components/BetterAccordion';
import { BetterDrawer } from '../components/BetterDrawer';
import { Loading } from '../components/Loading';
import { ChecklistDocument } from '../components/ProjectChecklist/ChecklistDocument';
import { MilestoneFiles } from '../components/ProjectChecklist/MilestoneFiles';
import { PreviousChecklist } from '../components/ProjectChecklist/PreviousChecklist';
import { ProjectDetailsBar } from '../components/ProjectChecklist/ProjectDetailsBar';
import { StatusChip } from '../components/ProjectChecklist/StatusChip';
import {
  ChecklistItemRule,
  ChecklistItemType,
  ChecklistItemVerificationStatus,
} from '../components/ProjectChecklist/constants';
import { tsToDate } from '../helpers/converters';
import { ErrorPage } from './ErrorPage';

const PROJECTS_API_PREFIX = '/api/v1/projects';
export const fetchProjectChecklistData = async ({ params }) => {
  try {
    const checklistData = await axios(
      `${PROJECTS_API_PREFIX}/${params.projectId}/checklist`,
    );
    return defer(checklistData.data);
  } catch (err: any) {
    return {
      error: {
        title: 'Error Fetching Checklist info',
        message:
          err.response.data.message ??
          'An error occurred while trying to fetch checklist information. Please try again later.',
        status: err.response.status ?? 403,
      },
    };
  }
};

const API_PREFIX = '/api/v1/checks';
const updateCheckById = async (id: string, checkUpdates) => {
  try {
    const response = await axios.patch(`${API_PREFIX}/${id}`, checkUpdates);
    return response.data;
  } catch (err: any) {
    console.error(err);
    return {
      error: {
        title: 'Error Updating Check',
        message:
          err.response.data.message ??
          'An error occurred while trying to update the check.',
        status: err.response.status,
      },
    };
  }
};

const createCheck = async (check) => {
  try {
    const response = await axios.post(`${API_PREFIX}`, check);
    return response.data;
  } catch (err: any) {
    console.error(err);
    return {
      error: {
        title: 'Error Creating Check',
        message:
          err.response.data.message ??
          'An error occurred while trying to create the check.',
        status: err.response.status,
      },
    };
  }
};

const deleteCheckById = async (id: string) => {
  try {
    const response = await axios.delete(`${API_PREFIX}/${id}`);
    return response.data;
  } catch (err: any) {
    console.error(err);
    throw {
      error: {
        title: 'Error Deleting Check',
        message:
          err.response.data.message ??
          'An error occurred while trying to delete the check.',
        status: err.response.status,
      },
    };
  }
};

export const ProjectChecklist = () => {
  const checklistData = useLoaderData() as any;

  /**
   * The complexity of this state is due to the fact that we need to keep track
   * of multiple things:
   * 1. All of the checks
   * 2. Dependent and Parent checks:
   *   - Parent check is identified by having 'EXISTS' rule and a unique title
   *   - Child checks are identified by having 'EQUALS' rule and a title matching the parent
   *   - While checks relate to a document and document relates to a milestone,
   *     checks can be dependent on checks from other documents and milestones
   *     which is why we want to keep track of all checks in one place
   * 3. Issues Identified checks by sunstone document to display in the document header,
   *    to be displayed in the document header, the check must have verificationStatus === ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
   *    and 'notes' must not be empty
   * 4. Issues Identified checks by milestone to display the milestone status,
   *    for a milestone to be ChecklistItemVerificationStatus.ISSUES_IDENTIFIED it must have at least one document
   *    with at least one required check with verificationStatus === ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
   */

  if (checklistData.error) {
    return (
      <ErrorPage
        status={checklistData.error.status}
        title={checklistData.error.title}
        message={checklistData.error.message}
      />
    );
  }

  const [checks, setChecks] = useState(() => {
    return (
      checklistData?.milestones
        ?.map((milestone) =>
          milestone?.documents
            ?.map((document) =>
              document?.checks.map((check) => ({
                ...check,
                milestoneId: milestone.Id,
              })),
            )
            ?.flat(),
        )
        ?.flat() ?? []
    );
  });

  const checklistOptions = useMemo(() => {
    const current = {
      label: `Current Version: ${tsToDate(
        checklistData?.latestLoanVersion?.CreatedDate,
      )}`,
      value: checklistData?.checklist.id,
      isCurrent: true,
    };
    const previous =
      checklistData?.previousChecklists?.map(({ checklist, loanVersion }) => ({
        label: `Previous Version: ${tsToDate(loanVersion?.CreatedDate)}`,
        value: checklist.id,
        isCurrent: false,
      })) ?? [];
    return [current, ...previous];
  }, [checklistData]);
  const [selectedChecklist, setSelectedChecklist] = useState(
    checklistOptions[0],
  );

  /**
   * We need to be able to easily find the parent check for any given check, so
   * we create a map here.
   */
  const parentChecksByTitle = useMemo(() => {
    const parentChecksByTitle = {};
    checks.forEach((check) => {
      if (check.rule === ChecklistItemRule.EXIST) {
        parentChecksByTitle[check.title] = check;
      }
    });
    return parentChecksByTitle;
  }, [checks]);

  const addCheck = useCallback(
    async (check) => {
      const newCheck = await createCheck(check);
      if (newCheck.error) {
        console.error(newCheck.error);
        return newCheck;
      }
      setChecks((checks) => [...checks, newCheck]);
      return newCheck;
    },
    [setChecks],
  );

  const deleteCheck = useCallback(
    async (id) => {
      const deletedCheck = await deleteCheckById(id);
      if (deletedCheck.error) {
        console.error(deletedCheck.error);
        return deletedCheck;
      }
      setChecks((checks) => checks.filter((check) => check.id !== id));
      return deletedCheck;
    },
    [setChecks],
  );

  const updateCheck = useCallback(
    async (id, checkUpdates) => {
      const updatedCheck = await updateCheckById(id, checkUpdates);
      if (updatedCheck.error) {
        console.error(updatedCheck.error);
        return updatedCheck;
      }
      setChecks((checks) => {
        const updatedChecks = checks.map((check) => {
          return check.id === updatedCheck.id ? updatedCheck : check;
        });
        return updatedChecks;
      });
      return updatedCheck;
    },
    [setChecks],
  );

  const sunstoneDocumentsNamesByMilestone = useMemo(
    () =>
      checklistData?.milestones?.reduce?.((acc, milestone) => {
        acc[milestone.Id] = new Set(
          milestone.documents.map((document) => document.Display_Name__c),
        );
        return acc;
      }, {}) ?? {},
    [checklistData.milestones],
  );

  const { milestoneStatusById, sunstoneDocumentStatusByDocumentName } =
    useMemo(() => {
      const sunstoneDocumentDerivedStatusByDocumentName = {};
      // A document is 'ISSUES_IDENTIFIED' if it has at least one check with verificationStatus === ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
      // and 'VERIFIED' if all of its checks have verificationStatus === 'VERIFIED'
      // else it is 'NOT_VERIFIED'
      const sunstoneDocumentNames: string[] = Object.values(
        sunstoneDocumentsNamesByMilestone,
      )
        .map((documentNames) => Array.from(documentNames as string[]))
        .flat();

      sunstoneDocumentNames.forEach((documentName) => {
        const documentChecks = checks.filter(
          (check) => check.sunstoneDocumentName === documentName,
        );
        const documentIssuesIdentifiedChecks = documentChecks.filter(
          (check) =>
            check.verificationStatus ===
              ChecklistItemVerificationStatus.ISSUES_IDENTIFIED && check.notes,
        );
        const isIssuesIdentified = documentChecks.some(
          (check) =>
            check.verificationStatus ===
            ChecklistItemVerificationStatus.ISSUES_IDENTIFIED,
        );
        const isVerified = documentChecks.every(
          (check) =>
            check.verificationStatus ===
              ChecklistItemVerificationStatus.VERIFIED ||
            !check.isRequired ||
            (check.type === ChecklistItemType.CALL &&
              (check.verificationStatus ===
                ChecklistItemVerificationStatus.CALLED_SPOKE_TO ||
                check.verificationStatus ===
                  ChecklistItemVerificationStatus.CALLED_VOICEMAIL ||
                check.verificationStatus ===
                  ChecklistItemVerificationStatus.NOT_NEEDED)) ||
            (check.type === ChecklistItemType.DOCUSIGN &&
              check.verificationStatus ===
                ChecklistItemVerificationStatus.SIGNED),
        );
        const status = isIssuesIdentified
          ? ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
          : isVerified
            ? ChecklistItemVerificationStatus.VERIFIED
            : ChecklistItemVerificationStatus.NOT_VERIFIED;

        sunstoneDocumentDerivedStatusByDocumentName[documentName] = {
          status,
          issuesIdentifiedChecks: documentIssuesIdentifiedChecks,
        };
      });

      const milestoneDerivedStatusByMilestoneId = {};
      checklistData.milestones.forEach((milestone) => {
        const sunstoneDocumentNames: string[] = Array.from(
          sunstoneDocumentsNamesByMilestone[milestone.Id],
        );
        const milestoneIssuesIdentifiedChecks = sunstoneDocumentNames
          .map((documentName) => {
            return sunstoneDocumentDerivedStatusByDocumentName[documentName]
              .issuesIdentifiedChecks;
          })
          .flat();
        const isIssuesIdentified = sunstoneDocumentNames.some(
          (documentName) => {
            return (
              sunstoneDocumentDerivedStatusByDocumentName[documentName]
                .status === ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
            );
          },
        );
        const isVerified = sunstoneDocumentNames.every((documentName) => {
          return (
            sunstoneDocumentDerivedStatusByDocumentName[documentName].status ===
            ChecklistItemVerificationStatus.VERIFIED
          );
        });
        const status = isIssuesIdentified
          ? ChecklistItemVerificationStatus.ISSUES_IDENTIFIED
          : isVerified
            ? ChecklistItemVerificationStatus.VERIFIED
            : ChecklistItemVerificationStatus.NOT_VERIFIED;
        milestoneDerivedStatusByMilestoneId[milestone.Id] = {
          status,
          issuesIdentifiedChecks: milestoneIssuesIdentifiedChecks,
        };
      });
      return {
        milestoneStatusById: milestoneDerivedStatusByMilestoneId,
        sunstoneDocumentStatusByDocumentName:
          sunstoneDocumentDerivedStatusByDocumentName,
      };
    }, [checks, sunstoneDocumentsNamesByMilestone]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [modalDims, setModalDims] = useState({ w: '80%', h: '80%' });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(<Loading />);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const handleFileOpen = async (fileId: string) => {
    try {
      setDrawerOpen(false);
      window.open(`/files/preview/${fileId}`);
    } catch (err: any) {
      setSnackMessage('❌ Failed to open the file');
      setSnackOpen(true);
    }
  };

  const handleMakeFilePublic = async (
    docId: string,
    fileId: string,
    isPublic: boolean,
  ) => {
    try {
      await axios.patch(
        `/api/v1/projects/file/make-public/${docId}/${fileId}/${isPublic}`,
      );
    } catch (err: any) {
      setSnackMessage('❌ Failed to set the file to public');
      setSnackOpen(true);
    }
  };

  const handleUpload = (msId: string, docId: string) => {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = false;

    input.click();
    input.onchange = (_) => {
      setModalContent(
        <>
          <Typography variant="h6">Uploading file(s)...</Typography>
          <LinearProgress />
        </>,
      );
      setModalDims({ w: '80%', h: '10%' });
      setModalOpen(true);
      uploadFiles(input.files, msId, docId);
    };
  };

  const uploadFiles = (files: any, msId: string, docId: string) => {
    const requests: Promise<any>[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('', file, file.name);
      requests.push(
        axios.post(
          `${PROJECTS_API_PREFIX}/file/milestone/${msId}/document/${docId}`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        ),
      );
    }

    axios
      .all(requests)
      .then((result) => {
        setModalOpen(false);
        setDrawerOpen(false);
        setSnackMessage('✅ File uploaded successfully');
        setSnackOpen(true);
      })
      .catch((err) => {
        setSnackMessage('❌ Failed to upload file');
        setSnackOpen(true);
        setModalOpen(false);
        setDrawerOpen(false);
      });
  };

  const toggleFileDrawer =
    (milestoneName: string, milestoneId: string, open: boolean) =>
    async (event) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
      if (open) {
        setDrawerContent(
          <>
            <Typography variant="body1">
              Loading milestone files, please wait...
            </Typography>
            <Loading />
          </>,
        );
        try {
          const { data } = await axios.get(
            `${PROJECTS_API_PREFIX}/milestone/${milestoneId}/docs?hardCopy=true`,
          );
          setDrawerContent(
            <Box className="fileListDrawer">
              <Box className="flex-row-space-between">
                <Typography variant="h6">{milestoneName}</Typography>
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <Close />
                </IconButton>
              </Box>
              <Box className="flex-row-start flex-gap-1">
                <Check fontSize="small" color="success" />
                <Typography variant="caption">
                  Shows file to installer
                </Typography>
              </Box>
              {data.documents.map((doc) => (
                <MilestoneFiles
                  milestoneId={milestoneId}
                  document={doc}
                  handleUpload={handleUpload}
                  handleFileOpen={handleFileOpen}
                  deleteCallback={(message: string) => {
                    setDrawerOpen(false);
                    setSnackMessage(message);
                    setSnackOpen(true);
                  }}
                  key={doc.id}
                  handleMakeFilePublic={handleMakeFilePublic}
                />
              ))}
            </Box>,
          );
        } catch (err: any) {
          setSnackMessage('Failed to fetch milestone docs');
          setSnackOpen(true);
        }
      }
    };

  if (checklistData.error) {
    return (
      <ErrorPage
        title={checklistData.error.title}
        message={checklistData.error.message}
        status={checklistData.error.status}
      />
    );
  }
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Await resolve={checklistData} errorElement={<ErrorPage />}>
          <ProjectDetailsBar project={checklistData.project} />
          <Typography variant="h4" fontSize={24}>
            Project {checklistData.project.Loan_App_Number__c} Loan Funding
            Checklist
          </Typography>
          {checklistOptions.length > 1 && (
            <TextField
              select
              value={selectedChecklist}
              onChange={(e) => {
                setSelectedChecklist(e.target.value);
              }}
              size="small"
              sx={{
                backgroundColor: 'white',
                border: 'none',
                my: 3,
              }}
            >
              {checklistOptions.map((checklistOption) => (
                <MenuItem key={checklistOption.value} value={checklistOption}>
                  {checklistOption.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {selectedChecklist.isCurrent ? (
            <>
              {checklistData.milestones.map((milestone) => {
                const { status, issuesIdentifiedChecks } =
                  milestoneStatusById[milestone.Id];
                return (
                  <Paper
                    key={milestone.Id}
                    sx={{
                      px: 3,
                      py: 1.5,
                      mt: 2,
                      borderRadius: 1,
                      '&>*': {
                        my: 2,
                        '&:first-of-type': { mt: 0 },
                        '&:last-of-type': { mb: 0 },
                      },
                    }}
                    elevation={0}
                  >
                    <BetterAccordion
                      elevation={0}
                      defaultExpanded={
                        milestone.Type__c ===
                        checklistData.project.Active_Milestone__c
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlined />}
                        sx={{
                          px: 0,
                          flexDirection: 'row-reverse',
                          [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
                            transform: 'rotate(-90deg)',
                            mr: 1.5,
                            [`&.${accordionSummaryClasses.expanded}`]: {
                              transform: 'rotate(0deg)',
                            },
                          },
                          [`& .${accordionSummaryClasses.content}`]: {
                            flexDirection: 'column',
                            margin: 0,
                            [`& .${accordionSummaryClasses.expanded}`]: {
                              margin: 0,
                            },
                          },
                        }}
                      >
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography
                            variant="h5"
                            fontSize={20}
                            fontFamily={'Lato'}
                            mr={1.5}
                          >
                            {milestone.Title__c}
                          </Typography>
                          <StatusChip status={status} />
                        </Box>

                        {issuesIdentifiedChecks.length !== 0 && (
                          <Box sx={{ my: 1.5 }}>
                            {issuesIdentifiedChecks.map((issue) => {
                              return (
                                <Typography
                                  key={issue.id}
                                  variant="body2"
                                  fontFamily={'Lato'}
                                >
                                  <Typography
                                    fontWeight={'bold'}
                                    variant="body2"
                                    component={'span'}
                                    sx={{
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    {issue.sunstoneDocumentName} | {issue.title}
                                    :
                                  </Typography>{' '}
                                  {issue.notes}
                                </Typography>
                              );
                            })}
                          </Box>
                        )}
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          p: 0,
                        }}
                      >
                        <Box className="flex-row-end">
                          <Button
                            variant="neutral"
                            startIcon={<Visibility />}
                            onClick={toggleFileDrawer(
                              milestone.Title__c + ' Documents',
                              milestone.Id,
                              true,
                            )}
                          >
                            View All Files
                          </Button>
                        </Box>
                        {milestone.documents
                          .filter(
                            (doc) =>
                              doc.Display_Name__c !== 'Additional Document' ||
                              doc.Display_Name__c === '',
                          )
                          .sort((a, b) => a.SortId__c - b.SortId__c)
                          .map((document) => {
                            const documentName = document.Display_Name__c;
                            const { status, issuesIdentifiedChecks } =
                              sunstoneDocumentStatusByDocumentName[
                                documentName
                              ];

                            const documentChecks = checks
                              .filter(
                                (check) =>
                                  check.sunstoneDocumentName === documentName,
                              )
                              .sort((a, b) => a.orderNumber - b.orderNumber);
                            return (
                              <ChecklistDocument
                                key={document.Id}
                                document={document}
                                status={status}
                                documentChecks={documentChecks}
                                parentChecksByTitle={parentChecksByTitle}
                                addCheck={addCheck}
                                deleteCheck={deleteCheck}
                                updateCheck={updateCheck}
                                checklistId={checklistData.checklist.id}
                              />
                            );
                          })}

                        {milestone.documents
                          .filter(
                            (doc) =>
                              doc.Display_Name__c === 'Additional Document',
                          )
                          .map((document) => {
                            const documentChecks = checks
                              .filter(
                                (check) =>
                                  check.sunstoneDocumentName ===
                                  document.Display_Name__c,
                              )
                              .sort((a, b) => a.orderNumber - b.orderNumber);
                            return (
                              <ChecklistDocument
                                key={document.Id}
                                document={document}
                                status={status}
                                documentChecks={documentChecks}
                                parentChecksByTitle={parentChecksByTitle}
                                addCheck={addCheck}
                                deleteCheck={deleteCheck}
                                updateCheck={updateCheck}
                                checklistId={checklistData.checklist.id}
                              />
                            );
                          })}
                      </AccordionDetails>
                    </BetterAccordion>
                  </Paper>
                );
              })}
            </>
          ) : (
            <>
              <PreviousChecklist
                checks={
                  checklistData.previousChecklists.find(
                    ({ checklist }) => checklist.id === selectedChecklist.value,
                  )?.checklist?.checks || []
                }
              />
            </>
          )}
        </Await>
      </Suspense>
      <BetterDrawer
        anchor={'right'}
        open={drawerOpen}
        sx={{ maxWidth: '500px' }}
        onClose={toggleFileDrawer('', '', false)}
      >
        <Box
          sx={{
            minWidth: '15em',
            p: '1em',
          }}
        >
          {drawerContent}
        </Box>
      </BetterDrawer>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: modalDims.w,
            maxHeight: modalDims.h,
            bgcolor: 'background.paper',
            borderRadius: '5px',
            boxShadow: 24,
            p: 4,
          }}
        >
          {modalContent}
        </Box>
      </Modal>
      <Snackbar
        open={snackOpen}
        message={snackMessage}
        autoHideDuration={3000}
        onClose={() => setSnackOpen(false)}
      />
    </>
  );
};
